import React from 'react';
import OverviewContent from './content/overview-content-component';
import CertificationContent from './content/certification-content-component';
import TrainingContent from './content/training-content-component';
import RefreshersContent from './content/refreshers-content-component';

type SectionKey = 'overview' | 'certification' | 'training' | 'refreshers';

type Section = {
  title: string;
  component: React.ComponentType;
};

const sections: Record<SectionKey, Section> = {
  overview: { title: "Overview", component: OverviewContent },
  certification: { title: "Safe Workplace Certification", component: CertificationContent },
  training: { title: "Media-Based Training", component: TrainingContent },
  refreshers: { title: "Quarterly Security Refreshers", component: RefreshersContent },
};

interface ContentAreaProps {
  activeSection: SectionKey;
}

const ContentArea: React.FC<ContentAreaProps> = ({ activeSection }) => {
  const ActiveComponent = sections[activeSection].component;

  return (
    <div className="flex-1 overflow-auto p-4 pt-20">
      <div className="bg-white shadow rounded-lg">
        <div className="p-6">
          <h2 className="text-xl font-bold mb-4">{sections[activeSection].title}</h2>
          <ActiveComponent />
        </div>
      </div>
    </div>
  );
};

export default ContentArea;