import React from 'react';

const RefreshersContent = () => {
  return (
    <>
      <h3 className="text-lg font-semibold mb-2">Ready to Download</h3>
      <p className="mb-4">Your Unlimited Package includes downloadable newsletters, videos, flyers and digital displays that can be distributed within your organization to reinforce key principles of safety and security. Share these resources with your personnel to further nurture a culture of safety and security.</p>
      
      <h4 className="font-semibold mb-2">Workplace Violence Prevention Begins With You</h4>
      <p className="mb-2">These resources reinforce the fundamentals of preventing violence in the workplace.</p>
      <button onClick={() => window.open('https://spaces.hightail.com/receive/YUNuTigDIU', '_blank')} className="bg-primary text-white px-4 py-2 rounded mb-4 hover:bg-primary/80">
        Download
      </button>
      
      <h4 className="font-semibold mb-2">Holiday Safety Tips for a Joyful Season</h4>
      <p className="mb-2">These resources highlight best practices for staying safe during the holiday season.</p>
      <button onClick={() => window.open('https://spaces.hightail.com/receive/uzacejqeBp', '_blank')} className="bg-primary text-white px-4 py-2 rounded hover:bg-primary/80">
        Download
      </button>
    </>
  );
};

export default RefreshersContent;
