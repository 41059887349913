import React from 'react';
import { ChevronLeft, ChevronRight, Home, Award, BookOpen, RefreshCw, LucideIcon } from 'lucide-react';
import { SectionKey } from '../types/shared-types';

const sections: Record<SectionKey, { title: string; icon: LucideIcon }> = {
  overview: { title: "Overview", icon: Home },
  certification: { title: "Certification", icon: Award },
  training: { title: "Media Training", icon: BookOpen },
  refreshers: { title: "Security Refreshers", icon: RefreshCw },
};

interface NavigationProps {
  activeSection: SectionKey;
  setActiveSection: (section: SectionKey) => void;
  isNavOpen: boolean;
  setIsNavOpen: (isOpen: boolean) => void;
  isMobile: boolean;
}

const Navigation: React.FC<NavigationProps> = ({ activeSection, setActiveSection, isNavOpen, setIsNavOpen, isMobile }) => {
  return (
    <div className={`
      bg-white shadow-lg transition-all duration-300 ease-in-out flex flex-col
      ${isMobile 
        ? `fixed inset-y-0 left-0 z-20 ${isNavOpen ? 'translate-x-0' : '-translate-x-full'}`
        : isNavOpen ? 'w-64' : 'w-16'
      }
    `}>
      <div className="h-[73px] px-4 border-b flex items-center justify-between">
        <h2 className={`text-lg font-semibold ${isNavOpen || isMobile ? '' : 'hidden'}`}>Safe Workplace Suite</h2>
        {!isMobile && (
          <button onClick={() => setIsNavOpen(!isNavOpen)} className="p-1 rounded-full hover:bg-gray-200">
            {isNavOpen ? <ChevronLeft size={24} /> : <ChevronRight size={24} />}
          </button>
        )}
      </div>
      <div className="p-4 flex-grow">
        {(Object.entries(sections) as [SectionKey, { title: string; icon: LucideIcon }][]).map(([key, section]) => (
          <button
            key={key}
            onClick={() => setActiveSection(key)}
            className={`w-full mb-2 px-2 py-2 rounded flex items-center justify-center ${activeSection === key ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700'}`}
            title={section.title}
          >
            <section.icon size={24} className={isNavOpen ? 'mr-2' : ''} />
            <span className={isNavOpen ? 'flex-grow text-left' : 'hidden'}>{section.title}</span>
          </button>
        ))}
      </div>
      {(isNavOpen || isMobile) && (
        <div className="p-4 border-t text-center">
          <span className="font-semibold text-primary">Unlimited Plan</span>
        </div>
      )}
    </div>
  );
};

export default Navigation;