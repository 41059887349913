import React from 'react';

const OverviewContent = () => {
  return (
    <>
      <h3 className="text-lg font-semibold mb-2">What is this Dashboard?</h3>
      <p className="mb-4">Your one-stop location for all the resources and services associated with your Safe Workplace Unlimited Package.</p>
      
      <h3 className="text-lg font-semibold mb-2">Your Account Representative</h3>
      <p>Name: Mike Ritchie</p>
      <p>Phone: 5094811182</p>
      <p>Email: michael.ritchie@cpps.com</p>
      
      <h3 className="text-lg font-semibold mt-4 mb-2">Questions?</h3>
      <p>Need assistance with troubleshooting? Upgrades? Customizations? Contact your Account Representative or email CPPS at info@CPPS.com for any assistance you need.</p>
    </>
  );
};

export default OverviewContent;
