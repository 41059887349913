import React from 'react';
import ProfileMenu from './profile-menu-component';

interface HeaderProps {
  onLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({ onLogout }) => {
  return (
    <div className="absolute top-0 left-0 right-0 h-[72px] flex items-center justify-between px-4 z-10">
      <h1 className="text-2xl font-bold">Dashboard</h1>
      <ProfileMenu onLogout={onLogout} />
    </div>
  );
};

export default Header;
