import React from 'react';
import { Menu } from 'lucide-react';

interface MobileMenuProps {
  isNavOpen: boolean;
  setIsNavOpen: (isOpen: boolean) => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isNavOpen, setIsNavOpen }) => {
  return (
    <button 
      onClick={() => setIsNavOpen(!isNavOpen)} 
      className="sm:hidden p-4 bg-primary text-white"
    >
      <Menu size={24} />
    </button>
  );
};

export default MobileMenu;