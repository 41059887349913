import React, { useState, useEffect } from 'react';
import Navigation from './navigation-component';
import ContentArea from './content-area-component';
import MobileMenu from './mobile-menu-component';
import Header from './header-component';
import { SectionKey } from '../types/shared-types';

interface DashboardProps {
  onLogout: () => void;
}

const Dashboard: React.FC<DashboardProps> = ({ onLogout }) => {
  const [activeSection, setActiveSection] = useState<SectionKey>('overview');
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };
  
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex flex-col h-screen bg-gray-100 sm:flex-row">
      {isMobile && <MobileMenu isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />}
      
      <Navigation 
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        isNavOpen={isNavOpen}
        setIsNavOpen={setIsNavOpen}
        isMobile={isMobile}
      />

      <div className="flex-1 flex flex-col relative">
        <Header onLogout={onLogout} />
        <ContentArea 
          activeSection={activeSection}
        />
      </div>

      {isMobile && isNavOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-10"
          onClick={() => setIsNavOpen(false)}
        ></div>
      )}
    </div>
  );
};

export default Dashboard;