import React from 'react';

const TrainingContent = () => {
  return (
    <>
      <h3 className="text-lg font-semibold mb-2">Tailored to Your Organization</h3>
      <p className="mb-4">This area allows you to access and download any customized training you have partnered with our team to create. Simply click the download button to retrieve a folder containing SCORM zip files for all your tailored media-based training materials. If you have not customized any courses yet, this folder will be empty until you complete your first customization.</p>
      <button onClick={() => window.open('https://www.dropbox.com/scl/fo/namvxto116y6ycjlt30dz/AKNmuuVA5_ABGSuzEyIuVwM?rlkey=x9uvug3703y5zx7m6fnbrviqb&st=7pzu47do&dl=0', '_blank')} className="bg-primary text-white px-4 py-2 rounded hover:bg-primary/80">
        Download Customized Training
      </button>
    </>
  );
};

export default TrainingContent;
