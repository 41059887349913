import React, { useState } from 'react';

const CertificationContent: React.FC = () => {
  const [activeTier, setActiveTier] = useState<string | null>(null);

  const tiers = [
    {
      name: 'Tier 1',
      content: (
        <>
          <p className="font-semibold">Workplace Violence Prevention and Intervention Program Needs Assessment</p>
          <p>Please reach out to your Account Executive to schedule this assessment.</p>
          <p className="font-semibold mt-2">WVPI Policy, Governance, and Active Assailant Protocol Development</p>
          <p>Please reach out to your Account Executive so our team can begin developing these documents.</p>
          <p className="font-semibold mt-2">WVPI Periodic Inspection Checklist</p>
          <button onClick={() => window.open('https://www.dropbox.com/scl/fo/b7uoelysmla7iuq14zs1r/AF_8yzqpPyWjuBRv5Uhqv4s?rlkey=rm1onoslhjoq6eswlfg153wva&st=ja7xhjc2&dl=0', '_blank')} className="bg-primary text-white px-4 py-2 rounded mt-2 hover:bg-primary/80">
            Download Checklist
          </button>
        </>
      )
    },
    {
      name: 'Tier 2',
      content: (
        <>
          <p className="font-semibold">Safe Haven: Workplace Violence Prevention and Response for Employees eLearning Course</p>
          <p>For this program to meet industry standards, it needs to include your reporting procedures and logo. Please reach out to your account executive to get this information inserted into this program.</p>
          <p className="font-semibold mt-2">Workplace Violence Prevention and Intervention Awareness Training - Instructor Certification</p>
          <p>Please reach out to your account executive to get your people enrolled as certified instructors.</p>
        </>
      )
    },
    {
      name: 'Tier 3',
      content: (
        <>
          <p className="font-semibold">Safe Haven: Workplace Violence Prevention-Enhanced Guidance for Managers and Leaders eLearning Course</p>
          <p>For this program to meet industry standards, it needs to include your reporting procedures and logo. Please reach out to your account executive to get this information inserted into this program.</p>
          <p className="font-semibold mt-2">Workplace Violence Prevention and Intervention Manager Training - Instructor Certification</p>
          <p>Please reach out to your account executive to get your people enrolled as certified instructors.</p>
        </>
      )
    },
    {
      name: 'Tier 4',
      content: (
        <>
          <p className="font-semibold">Behavioral Threat Assessment & Management Service Package</p>
          <ul className="list-disc list-inside">
            <li>BTAM eLearning Course</li>
            <li>Live Training Session</li>
            <li>Threat Management Advisory Team Retainer</li>
          </ul>
          <p className="mt-2">Please reach out to your Account Executive when you are ready to begin Tier 4, and they will get you started.</p>
        </>
      )
    },
    {
      name: 'Tier 5',
      content: (
        <>
          <p className="font-semibold">Senior Leadership Training</p>
          <p>Please contact your Account Executive to set up this training.</p>
        </>
      )
    },
    {
      name: 'Capstone',
      content: (
        <p>If each of the above tiers has a checkmark, you are ready to be awarded the Safe Workplace Certification. The next step is for CPPS to gather all the supporting documentation and prepare a submission package. Upon approval of the submission package, CPPS will present your organization with a formal package that includes all the contents and supporting documentation necessary for the initial certification. As a special recognition of your achievement, we'll also provide a plaque that signifies your organization's commitment to workplace safety and security. Congrats on all your hard work!</p>
      )
    }
  ];

  return (
    <>
      <h3 className="text-lg font-semibold mb-2">Getting Started</h3>
      <p className="mb-4">The Safe Workplace Certification is a prestigious recognition that signifies your organization's commitment to safety. By partnering with CPPS, we'll help you develop a comprehensive Workplace Violence Prevention and Intervention (WVPI) program that aligns with the National Standards, OSHA directives, and the best practices in the industry. The certification is awarded when your organization completes all tiers of the program, demonstrating its dedication to creating a safe and secure workplace.</p>
      <button onClick={() => window.open('https://spaces.hightail.com/receive/TE5FNYxlos', '_blank')} className="bg-primary text-white px-4 py-2 rounded mb-4 hover:bg-primary/80">
        Download Handbook
      </button>
      
      {tiers.map((tier) => (
        <div key={tier.name} className="mb-4">
          <button 
            onClick={() => setActiveTier(activeTier === tier.name ? null : tier.name)}
            className="w-full text-left flex justify-between items-center bg-gray-200 p-2 rounded"
          >
            <span>{tier.name}</span>
            <span className="text-right">{activeTier === tier.name ? '▼' : '▶'}</span>
          </button>
          {activeTier === tier.name && (
            <div className="mt-2 p-4 bg-gray-100 rounded">
              {tier.content}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default CertificationContent;